.download-page {
  width: 500px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.download-page .logo {
  width: 100px;
  height: 100px;
  background-image: url('../../assets/images/icon.png');
  background-size: contain;
  margin-bottom: 20px;
}

.download-button {
  padding: 20px;
  color: white;
  background: rgb(0, 119, 121);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms;
  box-shadow: 0 3px 5px rgba(0,0,0,0.3);
}

.download-button:hover {
  background: rgb(1, 201, 204);
  transform: scale(1.2);
  box-shadow: 0 15px 20px rgba(0,0,0,0.3);
}
.download-button svg {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.other_os_link,
.other_os_link a {
  font-size: 0.8rem;
  color: grey;
}
.other_os_link a {
  text-decoration: underline;
  color: blue;
}