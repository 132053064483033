.app {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template: 20vh 70vh 10vh / 1fr;
}

.app header {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #222;
  text-transform: uppercase;
  font-weight: 900;
}

.app header h1 {
  font-size: 10vh;
  margin: 0;
  color: rgb(244, 227, 1);
  animation: blink 500ms forwards linear infinite;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
}

.app header h2 {
  font-size: 6vh;
  margin: 0;
  color: red;
}

.app footer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(250, 230, 6);
  background: rgb(1, 142, 250);
}
