.step_wrapper {
  width: 75vw;
  height: 50vw;
  max-width: 95vh;
  max-height: 63.33vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.step_wrapper img {
  width: 75vw;
  height: 30vw;
  max-width: 95vh;
  max-height: 38vh;
}

.step_wrapper h1 {
  color: rgb(0, 150, 209);
  font-weight: normal;
  font-size: 1.5rem;
  margin: 0 0 10px 0;
}
.step_wrapper p {
  color: rgb(105, 104, 104);
  font-weight: normal;
  font-size: 1rem;
}