nav {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ccc;
  background: #222;
}
nav > * {
  margin: 0 20px;
}

nav a {
  width: 8vh;
  height: 8vh;
  color: white;
  border-radius: 0.5vw;
  background: rgb(0, 119, 121);
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-radius: 100%;
  transition: 200ms all;
}
nav a:hover {
  background: rgb(1, 201, 204);
  transform: scale(1.1);
}

nav a svg {
  width: 4vh;
  width: 4vh;
}

nav a.disabled-true {
  background: rgb(0, 119, 121);
  opacity: 0.5;
  filter: grayscale(1);
  pointer-events: none;
}
nav a.disabled-true:hover {
  background: rgb(0, 119, 121);
  transform: none;
}
