html, body, #root {
  width: 100%;
  height: 100%;
  background: white;
  margin: 0;
  font-family: sans-serif;
  overflow: hidden;
  user-select: none;
}

* {
  font-size: 1.3rem;
  box-sizing: border-box;
  touch-action: manipulation;
  /* prevents double tap and zoom */
}

a {
  text-decoration: none
}

button {
  border: none;
  background: none;
  cursor: pointer;
  background-repeat: no-repeat;
  padding: none;
  display: flex;
  justify-content: center;
  align-items: center;
}